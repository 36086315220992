
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function verifyFitmentRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3573'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3775'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'aria-label': this.title
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7801'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7804'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'aria-label': this.title,
                        'key': '2869'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', '', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    function repeatSelects2(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3573'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3775'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'aria-label': this.title
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7801'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7804'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'aria-label': this.title,
                        'key': '2869'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', '', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__verify-fitment' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '68'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.verifyFitmentTitle || 'Verify fitment with your vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go cm_button cm_button__primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    Search\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear cm_button cm_button__secondary',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle,
        'role': 'button',
        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
    }, [_createElement('svg', {
            'xmlns': 'http://www.w3.org/2000/svg',
            'className': 'cm_icon cm_icon-reset',
            'height': '16px',
            'viewBox': '0 0 16 16',
            'key': '68120'
        }, _createElement('path', { 'd': 'M2.083,9H0.062H0v5l1.481-1.361C2.932,14.673,5.311,16,8,16c4.08,0,7.446-3.054,7.938-7h-2.021 c-0.476,2.838-2.944,5-5.917,5c-2.106,0-3.96-1.086-5.03-2.729L5.441,9H2.083z' }), _createElement('path', { 'd': 'M8,0C3.92,0,0.554,3.054,0.062,7h2.021C2.559,4.162,5.027,2,8,2c2.169,0,4.07,1.151,5.124,2.876 L11,7h2h0.917h2.021H16V2l-1.432,1.432C13.123,1.357,10.72,0,8,0z' }))])))) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__' + this.fits + ' cmTemplate_locked',
        'key': '7337'
    }, _createElement('div', { 'className': 'cm_verify-fitment_fitment__container' }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-check',
            'height': '20px',
            'role': 'img',
            'viewBox': '0 0 492 492',
            'key': '75840'
        }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, this.fits === 'yes' ? ['This Product Fits Your'] : null, this.fits === 'no' ? ['This product may not fit, please contact us to confirm if this fits your submodel.'] : null), _createElement('div', { 'className': 'cm_verify-fitment_body' }, _createElement('span', { 'className': 'cm_verify-fitment_vehicle' }, this.vehicleString || 'Vehicle'), _createElement('span', {
        'className': 'cm_verify-fitment_change',
        'onClick': this.changeVehicle
    }, 'Change')), this.fits === 'no' ? _createElement('div', {
        'className': 'cm_verify-fitment_compatible-parts-container',
        'key': '9026'
    }, _createElement('a', {
        'href': this.getVehicleUrl(),
        'className': 'cm_verify-fitment_compatible-parts'
    }, '\n            Click here for products that do fit your vehicle\n          ')) : null))) : null, this.template === 'inexact' ? _createElement('div', {
        'className': 'cm_vehicle-widget_specifier cmTemplate_inexact',
        'key': '9335'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, 'Please specify ', this.selectFields.join(', '), ' for your ', this.vehicleString), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects2.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button button button--primary cm_button cm_button__primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    Search\n  ')))) : null, this.template === 'universal' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__universal cmTemplate_universal',
        'key': '15823'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-check',
            'height': '20px',
            'role': 'img',
            'viewBox': '0 0 492 492',
            'key': '160170'
        }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, window.Convermax.config?.verifyFitmentUniversalTitle || 'Universal Fit'), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, window.Convermax.config?.verifyFitmentUniversalText || 'This product may require modification.'))) : null, this.template === 'unknown' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__unknown cmTemplate_unknown',
        'key': '17064'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-check',
            'height': '20px',
            'role': 'img',
            'viewBox': '0 0 492 492',
            'key': '172520'
        }, _createElement('path', { 'd': 'm484.13 104.48-16.116-16.116c-5.064-5.068-11.816-7.856-19.024-7.856s-13.964 2.788-19.028 7.856l-226.45 226.45-141.48-141.49c-5.064-5.06-11.82-7.852-19.028-7.852-7.204 0-13.956 2.792-19.024 7.852l-16.12 16.112c-5.068 5.076-7.852 11.836-7.852 19.036 0 7.204 2.784 13.96 7.852 19.028l159.74 159.74c0.212 0.3 0.436 0.58 0.696 0.836l16.12 15.852c5.064 5.048 11.82 7.572 19.084 7.572h0.084c7.212 0 13.968-2.524 19.024-7.572l16.124-15.992c0.26-0.256 0.48-0.468 0.612-0.684l244.78-244.76c10.5-10.476 10.5-27.52 4e-3 -38.008z' }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, 'Please check description below or contact us to confirm fitment.'))) : null);
}
        export const componentNames = ["cm:filterInput","cm:filterInput"]