
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                return _createElement('div', { 'className': 'product-grid-item grid__item one-quarter mobile--one-half grid-item--third-tablet' }, _createElement('div', { 'className': 'product-grid-item__image' }, _createElement('div', { 'className': 'product__media__container' }, _createElement('a', {
                    'className': 'product__media__holder',
                    'href': this.url,
                    'aria-label': this.title
                }, _createElement('deferred-loading', {
                    'data-product-media-featured': true,
                    'class': 'product__media__image product__media__image--hover-none'
                }, _createElement('figure', {
                    'className': 'lazy-image product__media product__media--featured-visible',
                    'data-product-image': true,
                    'data-grid-image': true
                }, _createElement('img', {
                    'className': 'img-object-contain',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError,
                    'width': '1000',
                    'height': '1000',
                    'loading': 'eager',
                    'fetchpriority': 'high'
                })))), _createElement('div', {
                    'className': 'product__badge',
                    'data-product-badge': true
                }, this.on_sale ? _createElement('div', {
                    'className': 'product__badge__item product__badge__item--sale',
                    'key': '873'
                }, _createElement('span', {}, 'Sale')) : null, this.out_of_stock ? _createElement('div', {
                    'className': 'product__badge__item product__badge__item--sold',
                    'key': '1007'
                }, _createElement('span', {}, 'Sold Out')) : null), _createElement('div', { 'className': 'product-grid-item__quick-buy' }, _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'acceptCharset': 'UTF-8',
                    'className': 'quick__form',
                    'encType': 'multipart/form-data',
                    'data-product-form': true,
                    'data-quickbuy-form': true
                }, this.variant_ids.length > 1 ? [_createElement('a', {
                        'href': this.url,
                        'aria-label': 'button',
                        'className': 'btn--quick square',
                        'key': '13771'
                    }, _createElement('span', { 'className': 'btn__inner' }, _createElement('svg', {
                        'aria-hidden': 'true',
                        'focusable': 'false',
                        'role': 'presentation',
                        'className': 'icon icon-shopping-cart',
                        'viewBox': '0 0 192 192'
                    }, _createElement('path', {
                        'd': 'M60 171a9 9 0 0 0 9-9 9 9 0 0 0-9-9 9 9 0 0 0-9 9 9 9 0 0 0 9 9ZM138 171a9 9 0 0 0 9-9 9 9 0 0 0-9-9 9 9 0 0 0-9 9 9 9 0 0 0 9 9Z',
                        'fill': 'currentColor'
                    }), _createElement('path', {
                        'd': 'M31.725 54h134.55l-19.8 69.3a11.926 11.926 0 0 1-11.55 8.7h-71.85a11.925 11.925 0 0 1-11.55-8.7l-27.15-94.95A6 6 0 0 0 18.6 24H6',
                        'stroke': 'currentColor',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round'
                    }), _createElement('circle', {
                        'className': 'cart-indicator',
                        'cx': '96',
                        'cy': '93',
                        'r': '15'
                    })), _createElement('span', { 'className': 'btn__text' }, this.out_of_stock ? 'Sold out' : 'Select options')))] : null, this.variant_ids.length === 1 ? [
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'quantity',
                        'value': '1',
                        'key': '23771'
                    }),
                    _createElement('input', {
                        'type': 'hidden',
                        'name': 'id',
                        'value': this.variant_ids[0],
                        'key': '23773'
                    }),
                    _createElement('button', {
                        'disabled': this.out_of_stock,
                        'type': 'submit',
                        'name': 'add',
                        'className': 'btn--quick square',
                        'data-add-to-cart': true,
                        'data-atc-trigger': true,
                        'key': '23775'
                    }, _createElement('span', { 'className': 'btn__inner' }, _createElement('svg', {
                        'aria-hidden': 'true',
                        'focusable': 'false',
                        'role': 'presentation',
                        'className': 'icon icon-shopping-cart',
                        'viewBox': '0 0 192 192'
                    }, _createElement('path', {
                        'd': 'M60 171a9 9 0 0 0 9-9 9 9 0 0 0-9-9 9 9 0 0 0-9 9 9 9 0 0 0 9 9ZM138 171a9 9 0 0 0 9-9 9 9 0 0 0-9-9 9 9 0 0 0-9 9 9 9 0 0 0 9 9Z',
                        'fill': 'currentColor'
                    }), _createElement('path', {
                        'd': 'M31.725 54h134.55l-19.8 69.3a11.926 11.926 0 0 1-11.55 8.7h-71.85a11.925 11.925 0 0 1-11.55-8.7l-27.15-94.95A6 6 0 0 0 18.6 24H6',
                        'stroke': 'currentColor',
                        'strokeLinecap': 'round',
                        'strokeLinejoin': 'round'
                    }), _createElement('circle', {
                        'className': 'cart-indicator',
                        'cx': '96',
                        'cy': '93',
                        'r': '15'
                    })), _createElement('span', { 'className': 'btn__text' }, this.out_of_stock ? 'Sold out' : 'Add to cart'), _createElement('span', { 'className': 'btn__loader' }, _createElement('svg', {
                        'height': '18',
                        'width': '18',
                        'className': 'svg-loader'
                    }, _createElement('circle', {
                        'r': '7',
                        'cx': '9',
                        'cy': '9'
                    }), _createElement('circle', {
                        'strokeDasharray': '87.96459430051421 87.96459430051421',
                        'r': '7',
                        'cx': '9',
                        'cy': '9'
                    }))))),
                    _createElement('div', {
                        'className': 'product-grid-item__error',
                        'role': 'alert',
                        'data-cart-errors-container': true,
                        'key': '23777'
                    })
                ] : null)))), _createElement('div', { 'className': 'product-grid-item__info product-grid-item__info--inline' }, _createElement('div', { 'className': 'product-grid-item__info-content' }, _createElement('a', mergeProps({
                    'className': 'product-grid-item__title font-heading',
                    'href': this.url,
                    'aria-label': this.removeHTML(this.title)
                }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('a', {
                    'className': 'product-grid-item__price-container price',
                    'href': this.url
                }, _createElement('div', {}, !this.price_varies ? [
                    _createElement('span', {
                        'className': 'product-grid-item__price price',
                        'key': '44241'
                    }, _createElement('span', { 'aria-hidden': 'true' }, '\n                ', this.formatPrice(this.price).split('.')[0], '\n                ', _createElement('sup', {}, this.formatPrice(this.price).split('.')[1]))),
                    this.on_sale ? _createElement('rt-vitrual', { 'key': '44243' }, _createElement('span', { 'className': 'product-grid-item__price product__price--compare price' }, _createElement('span', { 'aria-hidden': 'true' }, '\n                  ', this.formatPrice(this.compare_at_price).split('.')[0], '\n                  ', _createElement('sup', {}, this.formatPrice(this.compare_at_price).split('.')[1]))), _createElement('span', { 'className': 'product-grid-item__price product__price--off price' }, 'Save ', this.formatPrice(this.save_price))) : null
                ] : null, this.price_varies ? [_createElement('span', {
                        'className': 'product-grid-item__price price',
                        'key': '53411'
                    }, _createElement('span', { 'aria-hidden': 'true' }, '\n                From ', this.formatPrice(this.price).split('.')[0], '\n                ', _createElement('sup', {}, this.formatPrice(this.price).split('.')[1]), ' to\n                ', this.formatPrice(this.max_price).split('.')[0], '\n                ', _createElement('sup', {}, this.formatPrice(this.max_price).split('.')[1])), this.on_sale ? _createElement('span', {
                        'className': 'product-grid-item__price product__price--off price',
                        'key': '5836'
                    }, 'Save up to ', this.formatPrice(this.save_price)) : null)] : null)))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, window.Convermax.isVehicleSelected() ? 'Also fits your vehicle:' : window.Convermax.isTireRelated ? 'Also fits your tires:' : 'You might also like:')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []