//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-940:_1932,_9524,_5748,_580,_720,_7056,_7428,_4848;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-940')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-940', "_1932,_9524,_5748,_580,_720,_7056,_7428,_4848");
        }
      }catch (ex) {
        console.error(ex);
      }