
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonMobileRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBoxDialogButton(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide  cm_searchBoxDialogButton' }, _createElement('svg', {
                'aria-hidden': 'true',
                'focusable': 'false',
                'role': 'presentation',
                'className': 'icon icon-search',
                'viewBox': '0 0 192 192'
            }, _createElement('path', {
                'd': 'M87 150c34.794 0 63-28.206 63-63s-28.206-63-63-63-63 28.206-63 63 28.206 63 63 63ZM131.55 131.55 168 168',
                'stroke': 'currentColor',
                'strokeLinecap': 'round',
                'strokeLinejoin': 'round'
            })));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchBoxDialogButton"]